@use '~@flixbus/honeycomb-react/dist/scss/honeycomb-tools' as *;

.panelWrapper {
  z-index: 100001;

  > div {
    top: 0 !important;
    max-height: 100% !important;
  }
}

.panelHeader {
  text-align: center;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.15);
  padding: 0;
  position: relative;

  h2 {
    padding: cssvar(spacing-3) 0;
    margin: 0;
    font-size: cssvar(font-size-h3);
    line-height: cssvar(line-height-h3);
  }

  button {
    position: absolute;
    right: cssvar(spacing-1);
    margin-right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
