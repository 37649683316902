@use '~@flixbus/honeycomb-react/dist/scss/honeycomb-tools' as *;

.productStepperWrapper {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid cssvar(grayscale-30-color);
  width: 100%;

  &:last-child {
    border-bottom: 0;
  }
}

.productStepper {
  min-height: 44px;
  line-height: cssvar(line-height-primary);
  margin: 0;
  padding: 15px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
}

.productStepperInfo {
  flex-grow: 1;
  margin-right: cssvar(spacing-1);

  .productStepperTitle {
    font-weight: bold;
  }
}
