@use '~@flixbus/honeycomb-react/dist/scss/honeycomb-tools' as *;

@media (min-width: 600px) {
  .productWrapperList {
    overflow-y: auto;
    padding: 0 15px;
    max-height: 400px;
    width: auto;
    min-width: 350px;
  }
}

.infoMessage {
  margin: cssvar(spacing-2) 0 0;
}

.productWrapperExtendedHeader {
  border-bottom: 1px solid cssvar(grayscale-30-color);
  padding: 15px 0;
  display: flex;
  justify-content: space-between;

  a {
    float: right;
  }
}

.productWrapperExtendedFooter {
  border-top: 1px solid cssvar(grayscale-30-color);
  padding: 15px 0;
}

@media (max-width: 599px) {
  .productWrapperExtendedHeader {
    padding: 5px 0 15px;
  }
  .productWrapperList {
    flex: 1;
  }
}
.productWrapperList {
  .productButton {
    border: none;
    height: cssvar(spacing-4);
    line-height: cssvar(spacing-4);
    text-align: left;
    min-width: 0;
    color: cssvar(link-color);
    padding: 0;
    display: block;
  }

  .backButton {
    display: inline;
  }

  .backProduct {
    width: auto;
    display: inline-block;
  }
}
