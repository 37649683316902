@use '~@flixbus/honeycomb-react/dist/scss/honeycomb-tools' as *;

.fieldset {
  display: flex;
  margin: 0;
  margin-bottom: cssvar(spacing-3);
  margin-left: calc(#{cssvar(spacing-4)} * -1);

  div[class*='hcr-fieldset__item'] {
    margin-left: cssvar(spacing-4);
    margin-top: cssvar(spacing-2);
  }

  label:before,
  label:after {
    box-sizing: border-box;
  }
}
