@use '~@flixbus/honeycomb-react/dist/scss/honeycomb-tools' as *;

.panelFooter {
  display: flex;
  align-items: center;
  height: cssvar(spacing-10);
  bottom: 0;
  width: 100%;
  background: cssvar(bg-primary-color);
  text-align: center;
  box-shadow: 0 -1px 15px rgba(0, 0, 0, 0.15);
  padding: 0 15px;
  z-index: 20001;
  position: relative;

  button {
    min-width: 100%;
  }
}

.panelHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: cssvar(spacing-10);
  background: cssvar(bg-primary-color);
  line-height: 60px;
  text-align: center;
  font-size: cssvar(font-size-h3);
  font-weight: bold;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.15);
  top: 0;
  position: relative;

  button {
    margin-right: 0;
    position: absolute;
    right: cssvar(spacing-1);
    top: 50%;
    transform: translateY(-50%);
  }
}

.panelContent {
  padding-top: 0;
}

.panelWrapper > div {
  height: 100% !important;
}
