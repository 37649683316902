@use '~@flixbus/honeycomb-react/dist/scss/honeycomb-tools' as *;

.locationSwitcher {
  position: absolute !important;
  right: 4px;
  bottom: 0;
  width: cssvar(spacing-6) !important;
  height: cssvar(spacing-6) !important;
  padding: 0;
  z-index: 1;
  background-color: cssvar(bg-primary-color);
  border-radius: 50% !important;
  border-color: cssvar(input-border-color);

  @include on-bp(xl) {
    width: 28px !important;
    height: 28px !important;
  }

  &:disabled {
    display: none;
  }

  &.rotate {
    //TODO: temporary solution. Replace styling by tags with styling by classes in MEES-3489
    > span {
      animation: spin 0.2s ease-out;
    }
  }

  @keyframes spin {
    100% {
      transform: rotate(180deg);
    }
  }
}

@media (max-width: 599px) {
  .locationSwitcher {
    top: 50%;
    transform: translateY(-50%);
    right: 12px;

    //TODO: temporary solution. Replace styling by tags with styling by classes in MEES-3489
    > span {
      transform: rotate(90deg);
    }

    @keyframes spin {
      100% {
        transform: rotate(270deg);
      }
    }
  }
}

@media (min-width: 600px) {
  .locationSwitcher {
    left: 50%;
    right: 0;
    transform: translateX(-50%);
    bottom: 4px;
  }
}
