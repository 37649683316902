@use '~@flixbus/honeycomb-react/dist/scss/honeycomb-tools' as *;

.searchButtonWrapper {
  box-sizing: border-box;
  width: 100%;
  padding-left: 0;

  .searchButton {
    display: flex;
    justify-content: center;
    white-space: nowrap;
    width: 100%;
  }
}
