@use '~@flixbus/honeycomb-react/dist/scss/honeycomb-tools' as *;
@use 'app/styles/panels' as *;

.panelWrapper {
  z-index: 100001;

  > div {
    top: 0 !important;
    max-height: 100vh !important;
  }
}
