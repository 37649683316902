@use '~@flixbus/honeycomb-react/dist/scss/honeycomb-tools' as *;

.continent {
  color: cssvar(grayscale-70-color);
  line-height: 2.3rem;
  background-color: cssvar(grayscale-10-color);
  padding: 0 cssvar(spacing-1);
  font-weight: cssvar(font-weight-bold);
  display: flex;
  align-items: center;
  border-bottom: 1px solid cssvar(grayscale-30-color);
  width: 100%;
}

.input {
  padding: cssvar(spacing-half) cssvar(spacing-half)
    calc(#{cssvar(spacing-2)} + #{cssvar(spacing-half)});

  & [class*='hcr-input__info'] {
    margin: 0;
  }

  @media (min-width: 600px) {
    padding: 0;
  }
}

.autocomplete {
  input::-webkit-search-cancel-button {
    display: none;
  }

  ul:last-child {
    li:hover,
    li[aria-selected='true'] {
      background-image: none;

      > div > div {
        background-image: cssvar(hover-layer-color);
      }
    }
  }

  .options {
    position: static;
    max-height: 100% !important;
    margin: 0 calc(#{cssvar(spacing-2)} * -1);
    overflow-y: auto;
    box-shadow: none;
    background-image: none;

    @media (min-width: 600px) {
      min-width: 400px;
      max-height: 420px !important;
      position: absolute;
      margin: 0;
      box-shadow:
        0 2px 6px rgba(0, 0, 0, 0.05),
        0 0 0 1px rgba(0, 0, 0, 0.07);
    }

    ul {
      background-image: none;
      margin-top: 0 !important;
    }

    li {
      padding: 0;
      border-bottom: 0 !important;
    }
  }
}

.option {
  display: block;
}

.cityName {
  display: block;
}

.additionalInfo {
  display: block;
  font-size: cssvar(font-size-small);
  font-weight: 100;
  color: cssvar(grayscale-90-color);
}
.cityName,
.additionalInfo {
  line-height: cssvar(line-height-primary);
}

.optionWrapper {
  display: flex;
  justify-content: space-between;
  padding: 0 cssvar(spacing-1);

  @media (min-width: 600px) {
    padding: 0;
  }
}

// to increase styles weight
.autocomplete .removeButton {
  cursor: pointer;
  border: none;
  background: none;
  padding: cssvar(spacing-1);
}

.errorMessage {
  padding: 20px 14px;
  position: absolute;
  width: 100%;
  min-width: 400px;
  box-shadow:
    0 2px 6px rgba(0, 0, 0, 0.05),
    0 0 0 1px rgba(0, 0, 0, 0.07);
  background: cssvar(bg-primary-color);
  border-radius: cssvar(spacing-half);
  z-index: 3;

  @media (max-width: 599px) {
    left: 0;
    height: 100%;
    border: none;
    box-shadow: none;
  }
}

.titleIcon {
  margin: 0 2px;
}

.station {
  padding-left: cssvar(spacing-2);
  margin-bottom: cssvar(spacing-half);

  .cityName {
    display: flex;
    width: 100%;
    > span {
      display: inline-block;
      vertical-align: top;
    }
  }
}

.pinIcon {
  margin-right: cssvar(spacing-1);
  min-width: 20px;
  margin-top: 2px;
}

.allStopsLabel {
  margin-left: cssvar(spacing-half);
}
