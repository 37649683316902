@use '~@flixbus/honeycomb-react/dist/scss/honeycomb-tools' as *;

.mapLink {
  background: cssvar(grayscale-10-color);
  padding: cssvar(spacing-1);
  border-radius: cssvar(spacing-half);
  display: flex;
  align-items: center;
  text-decoration: none;
  color: currentColor;
}

.title {
  margin: 0;
}

.imageWrapper {
  width: 42px;
  height: 42px;
  margin-right: cssvar(spacing-2);
}

.image {
  width: 100%;
  height: 100%;
}

.fakeLink {
  color: cssvar(link-color);
  text-decoration: underline;

  &.isNeptune {
    color: cssvar(ui-primary-dark-color);
  }
}

.mapWrapper {
  width: 100%;
  font-weight: normal;
}
