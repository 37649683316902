@use '~@flixbus/honeycomb-react/dist/scss/honeycomb-tools' as *;

.productPicker {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;

  label::first-letter {
    text-transform: uppercase;
  }
}
