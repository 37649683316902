@use '~@flixbus/honeycomb-react/dist/scss/honeycomb-tools' as *;

:global {
  #search-mask-component {
    background: #ffffff;
  }
}

.searchMask {
  background: cssvar(bg-primary-color);
  box-sizing: border-box;
  padding: 0 0 cssvar(spacing-4);
  color: cssvar(content-primary-color);
  font-family: cssvar(font-family-primary);
  font-size: cssvar(font-size-primary);
  font-weight: cssvar(font-weight-normal);
  line-height: cssvar(line-height-primary);
}

.searchMaskWrapper {
  margin-left: calc(#{cssvar(spacing-1)} * -1);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  > div {
    padding-left: cssvar(spacing-1);
  }
}

.tripModeWrapper {
  display: block;
  width: 100%;
}

.tripLocationWrapper {
  flex-basis: 100%;
  margin-bottom: cssvar(spacing-3);
  min-width: 0;
  flex-grow: 3;

  @media screen and (min-width: 600px) {
    flex-basis: 100%;
  }

  @media screen and (min-width: 1200px) {
    :not(.compact) & {
      margin-bottom: 0;
      flex-basis: 41%;
    }
  }
}

.dateRangeWrapper {
  flex-basis: 100%;
  flex-grow: 1;
  min-width: 0;

  @media screen and (min-width: 600px) {
    flex-basis: 25%;
  }

  @media screen and (min-width: 1200px) {
    flex-basis: 25%;
    flex-grow: 2;
  }
}

.tripProductWrapper {
  flex-basis: 100%;
  flex-grow: 1;
  margin-bottom: cssvar(spacing-3);
  min-width: 0;

  @media screen and (min-width: 600px) {
    flex-basis: 25%;
    margin-bottom: 15px;
  }

  @media screen and (min-width: 1200px) {
    margin-bottom: 0;

    :not(.compact) & {
      flex-basis: 16.6667%;
    }
  }
}

.searchButtonWrapper {
  flex-basis: 100%;

  @media screen and (min-width: 600px) {
    flex-basis: 25%;
  }

  @media screen and (min-width: 1200px) {
    margin-top: calc(#{cssvar(spacing-4)} + #{cssvar(spacing-half)});
    :not(.compact) & {
      flex-basis: 16.6667%;
    }
  }
}

@media screen and (min-width: 600px) {
  .searchMask {
    padding-bottom: cssvar(spacing-4);
    padding-top: cssvar(spacing-2);
  }
}

.frontpage {
  min-height: 413px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}

@media screen and (min-width: 600px) {
  .frontpage {
    min-height: 209px;
  }
}

@media screen and (min-width: 1200px) {
  .frontpage {
    min-height: 163px;
  }
}

@media (max-width: 1199px) {
  .searchMask {
    width: auto;
    margin: 0;
    border: 0;
    padding-left: 12px;
    padding-right: 12px;
    box-shadow: none;
    border-bottom: 1px solid cssvar(grayscale-30-color) !important;
  }
}

@media (min-width: 1200px) {
  .searchMask:not(.compact) {
    width: 1170px;
    border-radius: cssvar(spacing-1);
    padding-left: cssvar(spacing-4);
    padding-right: cssvar(spacing-4);
    margin: 0 auto;
    border: 1px solid cssvar(grayscale-30-color);
  }

  .fullWidth:not(.compact) {
    width: 100%;
    max-width: 1226px;
  }
}

.compact {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-top: 0 !important;
  width: auto;
  border-bottom: 1px solid cssvar(grayscale-30-color) !important;
}

.fullWidth {
  border: none !important;
}

.pageDarkened {
  position: relative;
  box-shadow: 0 0 0 8000px rgba(0, 0, 0, 0.65);
  z-index: 100000;
}
