@use '~@flixbus/honeycomb-react/dist/scss/honeycomb-tools' as *;

.locationWrapper {
  box-sizing: border-box;
  width: 100%;
  padding-right: 0px;
  color: inherit;
  font: inherit;
  margin: 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;

  .locationWrapperDeparture,
  .locationWrapperArrival {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: cssvar(spacing-3);

    input {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    label {
      max-width: 100%;
    }
  }

  .locationWrapperArrival {
    margin-bottom: 0;

    input {
      padding-right: 5px;
    }
  }

  .locationWrapperDeparture {
    input {
      padding-right: 40px;
    }
  }

  @media screen and (min-width: 600px) {
    .locationWrapperDeparture,
    .locationWrapperArrival {
      width: 50%;
      margin-bottom: 0;
      padding: 0;
    }

    .locationWrapperDeparture [class*='hcr-input__container'] {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .locationWrapperArrival [class*='hcr-input__container'] {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 0;

      & input {
        padding-left: cssvar(spacing-9);
      }
    }
  }

  @media screen and (min-width: 1200px) {
    &:not(.compact) {
      .locationWrapperDeparture,
      .locationWrapperArrival {
        width: 50%;
        margin-bottom: 0;
      }
    }
  }
}
