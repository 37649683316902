@use '~@flixbus/honeycomb-react/dist/scss/honeycomb-tools' as *;

.debugSwitch {
  white-space: nowrap;
}

.debugSwitchButton {
  font-family: cssvar(font-family-primary);
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  background-color: rgba(0, 0, 0, 0.05);
  line-height: 30px;
  height: 30px;
  padding: 0 10px;
  text-decoration: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-weight: normal;
  outline: none;

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.2); // Is !important needed here?
    box-shadow:
      0 1px 1px rgba(0, 0, 0, 0.1),
      inset 0 2px 10px rgba(255, 255, 255, 0.1);
  }

  &:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  &:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &:active,
  &.debugSwitchButtonSelected {
    background: rgba(0, 0, 0, 0.1) !important;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow:
      inset 0 1px 2px rgba(0, 0, 0, 0.2),
      inset 1px 0 2px rgba(0, 0, 0, 0.2) !important;
  }
}
