@use '@flixbus/honeycomb-react/dist/scss/honeycomb-helpers' as *;

.fallback {
  animation: flick 1s ease-in-out infinite;

  @-moz-keyframes flick {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes flick {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes flick {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
}

.input ~ [class*='hcr-btn--has-icon'] {
  display: flex;
  align-items: center;
}
