.datePickerFallback {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;

  .dateInput {
    width: 100%;
  }

  @media (max-width: 1199px) {
    .dateInput {
      margin-bottom: 15px;
    }
  }

  .plusIcon {
    font-size: 14px;
  }
}
