.textWrapper {
  display: inline-block;
  text-overflow: ellipsis;
  overflow-x: clip;
  width: 100%;
  white-space: nowrap;

  &::first-letter {
    text-transform: uppercase;
  }
}
