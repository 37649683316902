@use '~@flixbus/honeycomb-react/dist/scss/honeycomb-tools' as *;

.messagePopup {
  padding: cssvar(spacing-1) 0 15px;

  a {
    color: cssvar(link-color);
    text-decoration: none;
  }

  &.hidden {
    display: none;
  }
}
