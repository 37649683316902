@use '~@flixbus/honeycomb-react/dist/scss/honeycomb-tools' as *;

.debugWrapper {
  font-family: cssvar(font-family-primary);
  width: 100%;
  height: 40px;
  top: 0;
  left: 0;
  position: fixed;
  background: #2c2c2c;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow:
    0 1px 0 #181818,
    0 1px 2px #181818,
    inset 0 40px 20px rgba(255, 255, 255, 0.1);
  z-index: 9;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  font-weight: lighter;
  padding: 0 30px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;

  .debug {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    height: 40px;
  }

  .debugSection {
    height: 30px;
    margin-right: 20px;
    display: flex;

    .debugSectionLabel {
      line-height: 30px;
      color: rgba(255, 255, 255, 0.4);
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
      margin-right: 10px;
    }
  }
}
